import React from 'react';

import { GuestRoutes } from '../../Routes';

const GuestLayout = () => (
    <div className="guest-content">
        <div className="guest-layout">
            <div className="guest-block">
                <svg className='logo-guest' width="132" height="25" viewBox="0 0 132 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M123.2 0.441176L119.093 6.25V0.441176H111.687V24.4853H119.093V9.04412H125.84L132 0.441176H123.2ZM94.8933 18.0882C91.74 18.0882 89.4667 15.7353 89.4667 12.5C89.4667 9.26471 91.8133 6.83824 94.8933 6.83824C97.9733 6.83824 100.32 9.26471 100.32 12.5C100.393 15.7353 98.0467 18.0882 94.8933 18.0882ZM94.8933 0C87.7067 0 82.06 5.51471 82.06 12.5C82.06 19.4853 87.7067 25 94.8933 25C102.08 25 107.727 19.4853 107.727 12.5C107.8 5.51471 102.153 0 94.8933 0ZM71.6467 18.0882C68.2733 18.0882 65.9267 15.8088 65.9267 12.5C65.9267 9.26471 68.3467 6.83824 71.5 6.83824C73.1133 6.83824 74.7267 7.5 75.9 8.67647L79.9333 3.01471C77.66 1.10294 74.6533 0 71.5 0C64.24 0 58.52 5.51471 58.52 12.5C58.52 19.6324 64.0933 25 71.5 25C75.02 25 77.9533 23.9706 80.3733 21.8382L76.34 16.1029C75.24 17.1324 73.6267 18.0882 71.6467 18.0882ZM45.1 0C42.9733 0 39.4533 1.02941 38.2067 2.57353V0.441176H31.02V24.4853H38.4267V9.26471C39.2333 8.23529 41.14 6.83824 43.4133 6.83824C44.66 6.83824 45.76 7.20588 46.42 7.94118C47.0067 8.52941 47.5933 9.41176 47.5933 11.7647V24.4853H55V9.04412C55 3.01471 50.6 0 45.1 0ZM12.8333 18.0882C9.68 18.0882 7.40667 15.7353 7.40667 12.5C7.40667 9.26471 9.75333 6.83824 12.8333 6.83824C15.9133 6.83824 18.26 9.26471 18.26 12.5C18.26 15.7353 15.9867 18.0882 12.8333 18.0882ZM17.9667 2.57353C16.4267 0.735294 14.1533 0 11.88 0C5.20667 0 0 5.51471 0 12.5C0 15.6618 1.1 18.6029 3.08 20.8824C5.28 23.4559 8.72667 24.9265 12.2467 24.9265C14.3733 24.9265 16.7933 24.0441 18.04 22.4265V24.4853H25.2267V0.441176H18.04V2.57353H17.9667Z" fill="white"/>
                </svg>
                <GuestRoutes />
            </div>
        </div>
        <div className="copy">&copy; {(new Date()).getFullYear()} Группа компаний АНКОР</div>
    </div>
);

export default GuestLayout;
